import { formatTypeSchema, TypeSchemaLike } from './type-schema-like.ts';
import { TypeKind, TypeSchema, checkValue } from './type-schema.ts';

export type ArrayTypeParams = {
    minLength?: number;
    maxLength?: number;
};

export function arraySchema<T>(itemType: TypeSchemaLike<T>, params: ArrayTypeParams = {}): TypeSchema<T[]> {
    let {
        minLength = 0,
        maxLength = Infinity,
    } = params;

    let formattedItemType = formatTypeSchema(itemType);

    return {
        kind: TypeKind.Other,
        optional: false,
        check(ctx, value) {
            let ok = Array.isArray(value)
                && value.length >= minLength
                && value.length <= maxLength;

            if (ok) {
                let array = value as T[];

                for (let i = 0; i < array.length; ++i) {
                    let item = array[i];

                    ok = checkValue(itemType, ctx.index(i), item) && ok;
                }

                return ok;
            }

            let expected = 'array';

            if (minLength === 0 && isFinite(maxLength)) {
                expected += ` with length >= ${minLength}`;
            } else if (minLength > 0 && !isFinite(maxLength)) {
                expected += ` with length <= ${maxLength}`;
            } else if (minLength !== 0 && isFinite(maxLength)) {
                expected += ` with length >= ${minLength} and <= ${maxLength}`;
            }

            return ctx.expected(expected, value);
        },
        serialize(buffer, value, options) {
            buffer.writeUint32(value.length);

            for (let item of value) {
                formattedItemType.serialize(buffer, item, options, null)
            }
        },
        deserialize(buffer, options) {
            let result: any[] = [];
            let length = buffer.readUint32();

            for (let i = 0; i < length; ++i) {
                result.push(formattedItemType.deserialize(buffer, options));
            }

            return result;
        },
    };
};