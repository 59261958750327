import { shuffleArray } from 'outpost';
import { BoardCard } from './board-card';
import { BOARD_ZONES } from './board-constants';
import { BoardPlayerId, BoardPlayer, BoardZoneName, BoardZone } from './board-types';
import { CardId, DeckId } from '../project-types';
import { Project } from '../project';

export class Board {
    players: { [Key in BoardPlayerId]: BoardPlayer } = {
        p1: initBoardPlayer('p1'),
        p2: initBoardPlayer('p2'),
    };

    private globalCardIndex: { [key: CardId]: BoardCard } = {};

    moveCardToZone(movedCard: BoardCard, targetZone: BoardZone) {
        this.registerCard(movedCard);

        if (movedCard.parentZone) {
            movedCard.parentZone.cards.remove(movedCard);
        }

        if (movedCard.parentCard) {
            movedCard.parentCard.attachedCards.remove(movedCard);
            movedCard.parentCard = null;
        }

        let pushedCards = [];

        if (targetZone.metadata.kind === 'stack') {
            pushedCards.push(...movedCard.attachedCards, movedCard);
            movedCard.attachedCards.forEach(card => card.parentCard = null);
            movedCard.attachedCards = [];
        } else {
            pushedCards.push(movedCard);
        }

        targetZone.cards.push(...pushedCards);
        pushedCards.forEach(card => {
            if (card.parentZone !== targetZone) {
                card.marks = [];
            }
            card.parentZone = targetZone;
        });

        if (targetZone.metadata.autoFlip === 'back') {
            pushedCards.forEach(card => card.isFlipped = true);
        } else if (targetZone.metadata.autoFlip === 'front') {
            pushedCards.forEach(card => card.isFlipped = false);
        }
    }

    attachCard(movedCard: BoardCard, targetCard: BoardCard) {
        this.registerCard(movedCard);

        if (movedCard.parentZone) {
            movedCard.parentZone.cards.remove(movedCard);
            movedCard.parentZone = null;
        }

        if (movedCard.parentCard) {
            movedCard.parentCard.attachedCards.remove(movedCard);
            movedCard.parentCard = null;
        }

        targetCard.attachedCards.push(movedCard);
        targetCard.attachedCards.push(...movedCard.attachedCards);
        movedCard.attachedCards = [];
        targetCard.attachedCards.forEach(child => child.parentCard = targetCard);
    }

    flipCard(card: BoardCard) {
        card.isFlipped = !card.isFlipped;
    }

    revealCard(card: BoardCard) {
        card.isRevealed = !card.isRevealed;
    }

    rotateCard(card: BoardCard) {
        card.isRotated = !card.isRotated;
    }

    markCard(card: BoardCard, playerId: BoardPlayerId) {
        if (card.marks.includes(playerId)) {
            card.marks.remove(playerId);
        } else {
            card.marks.push(playerId);
        }
    }

    shuffleZone(zone: BoardZone | null) {
        if (zone) {
            shuffleArray(zone.cards);
        }
    }

    *cards(): IterableIterator<BoardCard> {
        for (let player of Object.values(this.players)) {
            for (let zone of Object.values(player.zones)) {
                for (let card of zone.cards) {
                    yield card;
                }
            }
        }
    }

    private registerCard(card: BoardCard) {
        this.globalCardIndex[card.cardId] = card;
    }

    getCardById(cardId: CardId): BoardCard | undefined {
        return this.globalCardIndex[cardId];
    }

    initDecks(project: Project, deckIds: [DeckId, DeckId]) {
        let decks: { [Key in BoardPlayerId]: DeckId } = {
            p1: deckIds[0],
            p2: deckIds[1]
        };

        let suffix = 1;

        for (let [key, deckId] of Object.entries(decks)) {
            let playerId = key as BoardPlayerId;
            let deck = project.getDeckById(deckId)!;

            for (let cardId of deck.cardIds) {
                let card = new BoardCard(`${cardId}#${suffix++}`, playerId);

                this.moveCardToZone(card, this.players[playerId].zones.deck);
            }

            this.shuffleZone(this.players[playerId].zones.deck);
        }
    }
};

function initBoardPlayer(playerId: BoardPlayerId): BoardPlayer {
    let zones: Partial<BoardPlayer['zones']> = {};

    for (let key in BOARD_ZONES) {
        let name = key as BoardZoneName;
        let metadata = BOARD_ZONES[name];
        let zone: BoardZone = {
            name,
            playerId,
            metadata,
            cards: [],
        };

        zones[name] = zone;
    }

    return {
        playerId,
        zones: zones as BoardPlayer['zones']
    };
}
globalThis.ALL_FUNCTIONS.push(Board);